import createDataContext from "./createDataContext";
import senseiApi from "../api/sensei";
import { timeSince } from "../calculations";
import { Device, DeviceStatus } from "../utils/types";

export interface SensorState {
  devices: Device[] | null;
  currentDevice: number;
  averagesInterval: string;
  startDateTime: string;
  endDateTime: string;
  authError: false;
  settingsOpen: boolean;
  deviceStatus: DeviceStatus | null;
}

interface SensorContext {
  state: SensorState;
  displayOffline: (displayBoolean: boolean) => void;
  setTempScale: (scale: string) => void;
  setLanguage: (id: number) => void;
  refreshSettings: () => void;
  fetchDevices: () => void;
  fetchMyDevices: () => void;
  fetchDeviceStatus: (did: number) => void;
  setCurrentDevice: (did: number) => void;
  setAveragesInterval: (interval: string) => void;
  setStartDateTime: (time: string) => void;
  setEndDateTime: () => void;
  clearError: () => void;
  toggleSettings: (toggle?: boolean) => void;
}

const sensorReducer = (state: any, action: any) => {
  switch (action.type) {
    case "get_devices":
      var tempDevices = action.payload.devices;
      if (state.devices === null || tempDevices.length > state.devices.length) {
        tempDevices.sort((a: any, b: any) =>
          new Date(a.lastupdate) < new Date(b.lastupdate) ? 1 : -1
        );
        tempDevices.forEach((device: any, index: number) => {
          tempDevices[index].weight = index;
          let mostRecent = new Date(tempDevices[index].lastupdate);
          mostRecent.setSeconds(mostRecent.getSeconds() + 300);
          let now = new Date();
          if (mostRecent > now) {
            tempDevices[index].online = true;
          } else {
            tempDevices[index].online = false;
          }
        });
      } else {
        tempDevices.forEach((device: any, index: number) => {
          state.devices.forEach((device2: any, index2: number) => {
            if (device.did === device2.did) {
              device.weight = device2.weight;
            }
          });
          let mostRecent = new Date(tempDevices[index].lastupdate);
          mostRecent.setSeconds(mostRecent.getSeconds() + 300);
          let now = new Date();
          if (mostRecent > now) {
            tempDevices[index].online = true;
          } else {
            tempDevices[index].online = false;
          }
        });
        tempDevices.sort((a: any, b: any) => (a.weight > b.weight ? 1 : -1));
      }
      return { ...state, devices: tempDevices, authError: false };
    case "get-device-status":
      let splitVSD = undefined;
      let builtVSD = null;
      if (!!action.payload?.vsd) {
        splitVSD = action.payload?.vsd?.split(",");
        builtVSD = {
          active: !!splitVSD[0],
          sensitivity: splitVSD[1],
          classification_threshold: splitVSD[2],
          classification_delay: splitVSD[3],
          detection_threshold: splitVSD[4],
        };
      }
      let parsedMisc = undefined;
      if (!!action.payload?.misc) {
        let tempMisc = JSON.parse(action.payload.misc);
        parsedMisc = {
          led: `${tempMisc.led * 2}%`,
          mac: tempMisc.mac,
          unit: tempMisc.unit,
          uart: tempMisc.uart,
          cleaning: timeSince(Date.now() - tempMisc.cleaning * 1000),
          ip: tempMisc.ip,
          server_ip: tempMisc.server_ip,
          server_port: tempMisc.server_port,
          fan_speed: tempMisc.fan_speed,
          fan_duty: tempMisc.fan_duty,
          uptime: tempMisc.uptime,
        };
      }

      return {
        ...state,
        deviceStatus: {
          did: action.payload.did,
          sid: action.payload.sid,
          // rssi: `${Math.min(Math.max(2 * (action.payload.rssi + 100), 0), 100)}%`,
          rssi: `${Math.min(
            Math.max(Math.round((95 + action.payload.rssi) * 1.666), 0),
            100
          )}%`,
          indentifier: action.payload.indentifier,
          lastupdate: timeSince(action.payload.lastupdate, "-ago"),
          sn: action.payload.sn,
          version: action.payload.version,
          vsd: builtVSD,
          misc: parsedMisc,
        },
      };
    case "set-current-device":
      return {
        ...state,
        currentDevice: action.payload,
        deviceStatus: undefined,
      };
    case "set-averages-interval":
      return { ...state, averagesInterval: action.payload };
    case "set-start-date-time":
      return { ...state, startDateTime: action.payload };
    case "set-end-date-time":
      return { ...state, endDateTime: action.payload };
    case "auth-error":
      return { ...state, authError: true };
    case "clear-error":
      return { ...state, authError: false };
    case "toggle-settings":
      return {
        ...state,
        settingsOpen:
          action.payload?.toggle !== undefined
            ? action.payload.toggle
            : !state.settingsOpen,
      };
    default:
      return state;
  }
};

const fetchDevices = (dispatch: any) => async () => {
  try {
    const response = await senseiApi.get("/get-devices");
    dispatch({ type: "get_devices", payload: response.data });
  } catch (error) {}
};

const fetchMyDevices = (dispatch: any) => async () => {
  try {
    const response = await senseiApi.get("/get-my-devices", {
      withCredentials: true,
    });
    dispatch({ type: "get_devices", payload: response.data });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const fetchDeviceStatus = (dispatch: any) => async (did: number) => {
  try {
    let response = await senseiApi.get(`get-status/${did}`, {
      withCredentials: true,
    });
    dispatch({
      type: "get-device-status",
      payload: response.data.status[0] ?? null,
    });
  } catch (error: any) {}
};

const setCurrentDevice = (dispatch: any) => (did: number) => {
  try {
    dispatch({ type: "set-current-device", payload: did });
  } catch (error) {}
};

const clearError = (dispatch: any) => () => {
  dispatch({ type: "clear-error" });
};

const toggleSettings = (dispatch: any) => (toggle?: boolean | undefined) => {
  dispatch({ type: "toggle-settings", payload: toggle });
};

const setAveragesInterval =
  (dispatch: any) => (interval: string | undefined) => {
    dispatch({ type: "set-averages-interval", payload: interval });
  };

const setStartDateTime = (dispatch: any) => (start: string | undefined) => {
  dispatch({ type: "set-start-date-time", payload: start });
};

const setEndDateTime = (dispatch: any) => (end: string | undefined) => {
  dispatch({ type: "set-end-date-time", payload: end });
};

export const {
  Provider,
  Context,
}: {
  Provider: ({ children }: { children: any }) => JSX.Element;
  Context: React.Context<SensorContext>;
} = createDataContext(
  sensorReducer,
  {
    fetchDevices,
    fetchMyDevices,
    fetchDeviceStatus,
    setCurrentDevice,
    setAveragesInterval,
    setStartDateTime,
    setEndDateTime,
    clearError,
    toggleSettings,
  },
  {
    devices: null,
    currentDevice: -1,
    averagesInterval: "mins",
    startDateTime: "now",
    endDateTime: "now",
    authError: false,
    settingsOpen: false,
    deviceStatus: null,
  }
);
