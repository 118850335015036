// import React, { useEffect, useState, useContext } from 'react';
// import i18n from "i18next";
// import { useTranslation, initReactI18next } from "react-i18next";
//import { ipcRenderer } from 'electron';
// import Backend from 'i18next-xhr-backend';
import "./App.scss";
// @ts-ignore
// import { BrowserRouter as Router, Route, NavLink, Switch, useHistory } from "react-router-dom";
import { Provider as AuthProvider } from "./context/AuthContext";
import { Provider as SensorProvider } from "./context/SensorContext";
import { Provider as SettingsProvider } from "./context/SettingsContext";
// import Settings from "./screens/Settings"
// import Dashboard from "./screens/Dashboard"
// import DeviceFull from "./screens/DeviceFull"
// import Main from './Main'
import Routes from "./Routes";

function App(props: any) {
  return (
    <AuthProvider>
      <SensorProvider>
        <SettingsProvider>
          <Routes />
          {/* <Main /> */}
        </SettingsProvider>
      </SensorProvider>
    </AuthProvider>
  );
}

export default App;
