import { useEffect } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router";

export type PrivateRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  redirectPath: string;
  setRedirectPath: (path: string) => void;
} & RouteProps;

const PrivateRoute = ({
  isAuthenticated,
  authenticationPath,
  redirectPath,
  setRedirectPath,
  children,
  ...routeProps
}: PrivateRouteProps) => {
  const currentLocation = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      setRedirectPath(currentLocation.pathname);
    }
  }, [isAuthenticated, setRedirectPath, currentLocation]);

  // if (isAuthenticated && redirectPath === currentLocation.pathname) {
  //   return <Route {...routeProps} />;
  // } else {
  //   console.log("Redirecting...")
  //   return <Redirect to={{ pathname: isAuthenticated ? redirectPath : authenticationPath }} />;
  // }

  return (
    <>
      {isAuthenticated && redirectPath === currentLocation.pathname ? (
        <Route {...routeProps} render={(props: any) => children}></Route>
      ) : (
        // TODO: Need to modify this if we have more than 1 PrivateRoute
        // Using <Route {...routeProps} /> causes component to be recreated mulitple times
        // <ClaimCode />
        <Redirect
          to={{ pathname: isAuthenticated ? redirectPath : authenticationPath }}
        />
      )}
    </>
  );
};

export default PrivateRoute;
