import React, { useContext, useEffect } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { Context as AuthContext } from "./context/AuthContext";

import Main from "./Main";
import LoadingSpinner from "./components/LoadingSpinner";

import { paths } from "./utils/const";

const Auth = React.lazy(() => import("./screens/auth"));

const Routes = () => {
  const {
    state: authState,
    checkSignin,
    // signout
  } = useContext(AuthContext);

  useEffect(() => {
    checkSignin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      {authState.signin == null ? (
        <LoadingSpinner />
      ) : (
        <Switch>
          <Route path={paths.auth}>
            <Auth />
          </Route>
          <Route path={paths.root}>
            <Main />
          </Route>
          <Redirect to={paths.auth} />
        </Switch>
      )}
    </Router>
  );
};

export default Routes;
